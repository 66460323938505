import React from 'react';
import '../App.css';

const Projects = ({ projects }) => {
  const { sectionTitle, items } = projects;

  return (
    <div className="projects-container">
      <h1>{sectionTitle}</h1>
      {items.map((project) => (
        <a key={project.id} href={project.url}>
          {project.title}
        </a>
      ))}
    </div>
  );
};

export default Projects;
