import React from 'react';
import '../App.css';


const Home =({home}) =>{
    return(
        <section className="welcome-section" id="welcome-section">
        <h1>{home.mainText}</h1>
      </section>
    )
}

export default Home
