import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import ThemeToggle from './ThemeToggle';

function Navbar({ onThemeChange, navbarData }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !menuButtonRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div>
      <nav id="navbar">
        <div id="left-section">
          {navbarData.links.map((link, index) => (
            <Link key={index} to={link.path} onClick={() => setMenuOpen(false)}>
              {link.label}
            </Link>
          ))}
        </div>

        <div id="navbar-center-section">
          <span id="site-name">{navbarData.siteName}</span>
          <ThemeToggle onThemeChange={onThemeChange} />
        </div>

        <button id="navbar-menu-button" ref={menuButtonRef} onClick={toggleMenu}>
          &#9776;
        </button>

        <div ref={menuRef} id="navbar-menu-items" className={menuOpen ? 'active' : ''}>
          {navbarData.links.map((link, index) => (
            <Link key={index} to={link.path} onClick={() => setMenuOpen(false)}>
              {link.label}
            </Link>
          ))}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
